import React from 'react'
import "./CareerHome.css";
import data from "./CareerHome.json";
const CareerHome = () => {
  return (
    <div>
       <div className='maindiv'>
      <div className='career-hero-div'>
<h1 className='bgImgHead'>START YOUR CAREER JOURNEY WITH US</h1>
      </div>
<div className="container pt-5 pb-5">
      <div className="row">
        <h4 className='mt-md-5 mb-md-5 careertitle' >DARE TO DREAM</h4>
        {data.Section2.map((NewData,index) => {
          return (
            <div key={index} className="col-md-4 col-sm-12 mt-4">
              <div className='card cardbox'>
                <a className='alink' href={NewData.Link}>
                  <div className='carercard-img-div'>
                  <img
                    className='card-img-top careercardimg' 
  
                    src={NewData.Image}
                    alt="pp"
                  />
                  </div>
                  <div className="card-body careercard-text-div">
                    <h5 className='card-title careerhead'>{NewData.Heading}</h5>
                    <p className='card-text careertxt'>{NewData.Content}</p>
                  </div>
                 
                </a>
              </div>
            </div>
          );
        })}
        </div>
      </div>
      </div>
    </div>
  )
}
export default CareerHome