import React from 'react';
import AboutCard from "./HomePage/AboutCard/Aboutcard"
import BusinessCard from "./HomePage/BusinessCard/BusinessCard"
import HomeBlog from './HomePage/HomeBlog/HomeBlog'
import Sustainability from './HomePage/Sustainability/Sustainability'
import OurCommitment from './HomePage/OurCommitment/OurCommitment'
import Carousel from './HomePage/Carousel/Carousel'
import '../Components/pages/News/News.css'
import Slideout from './HomePage/Slideoutpage/Slideout'
import WomekiEvents from './HomePage/HomeBlog/WomekiEvents';
const HomePage = () => {
  return (
    <div>
      <Slideout/>
      <Carousel/>
      <BusinessCard/>
      <AboutCard/>
      <HomeBlog/>
      <Sustainability/>
      <WomekiEvents/>
      <OurCommitment/>
    </div>
  )
}
export default HomePage