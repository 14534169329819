import React from "react";
import data from "./BusinessCard.json"
import "./BusinessCard.css"

const BusinessCard = () => {
  return (
    <div className="main-verticals-about-div" >
    <div className="container" >
      <div className="row  outer" >
       
          <h1 className="heading-verticals-business">BUSINESS</h1>
          <p className="text-verticals-business">Womeki - Nurturing Women, Supporting Men and Inspiring Kids</p>
          {
            data.Section1.map((NewsCard) =>{
              return(
                <div className=" col-lg-3 col-md-6 col-sm-12" >
                  <div className="inner">
                  <div className="home-businesscard-div">
                    <div className="d-flex align-items-center justify-content-center">
                    <div className="img-border" >
                  <img className="businesscard-icon" src={NewsCard.Url} alt="mn" /> 
                  </div>
                  </div>
                  <h2 className="mt-3 verticals-card-heading-business ">{NewsCard.Heading1}</h2>
                  <p className=" p-1 verticals-card-text-business" >{NewsCard.Content1}</p>
                 </div>
                  </div>
               
                </div>
              
              )
            })
          }
          
      
      </div>

    </div>
    <a className="Button-for-bussiness" href="/business" >VIEW MORE</a>
    </div>
  )
}

export default BusinessCard
