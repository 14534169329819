import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import "./CareerCard1.css";
import data from "./CareerCard1.json";



const CareerCard1 = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleSeeMore = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      <div className="container pt-5 pb-5">
        <div className="row">
          {data.Section1.map((NewData, index) => {
            return (
              <div key={index} className="d-lg-flex d-sm-block">
                <div className="col-lg-6 col-sm-12 CareerImgDiv">
                  <img className="cardimg" alt="p" src={NewData.Image} />
                </div>
                <div className="col-lg-6 col-sm-12 CareerTextDiv">
                  <h4 className="card1head">{NewData.Heading}</h4>
                  <p className="card1text">{NewData.Content}</p>
                  {expandedIndex === index && (
                  <p className="card1text">{NewData.Content1}</p>
                  )}
                  <div className='more-button'>
            <button className='see-more-btn' onClick={() => toggleSeeMore(index)} >
            {expandedIndex === index ? "See Less" : "See More"}
            </button>
          </div>
                </div>
              </div>
            );
          })}
        </div>
        <h4 className="mt-5 mb-5 CaringHeading">CARING FOR OUR EMPLOYEE</h4>
        <Swiper
          className=" Carouseldiv "
          modules={[Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          speed={1000}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
        >
          <div className="row">
            {data.Section2.map((NewData, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className=" d-lg-flex">
                    <div className="col-lg-6  col-md-12 col-sm-12">
                      <div className="CarouselDivImg">
                        <img
                          className="Carousel-img1"
                          src={NewData.Image}
                          alt="ss"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="carouselcard1head">
                        <h5 className=" card1head">
                          {NewData.Heading}
                        </h5>
                        <p className="carousel-content card1text">
                          {NewData.Content}
                        </p>
                        {expandedIndex === index && (
                        <p className="carousel-content card1text">
                          {NewData.Content1}
                        </p>
                        )}
                        <div className='more-button'>
            <button className='see-more-btn' onClick={() => toggleSeeMore(index)} >
            {expandedIndex === index ? "See Less" : "See More"}
            </button>
          </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </div>
        </Swiper>
      </div>
    </div>
  );
};
export default CareerCard1;