import React, { useState } from "react";
// import data from "./Sustainability.json";
import "./Sustainability.css";
import HomeNews from "../../HomePage/HomePagesocialNews/News/HomeNews";
import HomeBlog from "../../HomePage/HomePagesocialNews/Blogs/HomeBlogs";
import SocialMedia from "../../HomePage/HomePagesocialNews/SocialMedia/Socialmedia";
import Events from "../HomePagesocialNews/Events/Events";
const Sustainability = () => {
  const [active, setActive] = useState("Realestate");
  return (
    <div>
      <div className="News-Social-new-Background">
        <div className="container">
          <div className="row">
            <div className=" col-12 ">
              <div className="News-Social-new">
                <h4 className="News-Social-new-heading">WOMEKI IN SPOTLIGHT</h4>
                <p className="News-Social-new-text">
                  More than a Name, a Legacy in the Making.
                </p>
              </div>
              <div className={` fluid maindiv-home-social`}>
                <div className={`container `}>
                  <div className={`row col-8 text-center businessnavs`}>
                    <div
                      className={`  col-3 p-2 border-end businessnavcol`}
                      onClick={() => setActive("Realestate")}
                    >
                    
                      NEWS
                    </div>
                    <div
                      className={`col-3 p-2 border-end businessnavcol`}
                      onClick={() => setActive("Education")}
                    >
                      BLOGS
                    </div>
                    <div
                      className={`col-3 p-2 border-end businessnavcol`}
                      onClick={() => setActive("Tech")}
                    >
                      SOCIAL MEDIA
                    </div>
                    <div
                      className={`col-3 p-2 border-end businessnavcol`}
                      onClick={() => setActive("Events")}
                    >
                      EVENTS
                    </div>
                  </div>
                </div>
                <div className="lastdiv mt-5 mb-5 ">
                  {active === "Realestate" && <HomeNews />}
                  {active === "Education" && <HomeBlog />}
                  {active === "Tech" && <SocialMedia />}
                  {active === "Events" && <Events/>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Sustainability;