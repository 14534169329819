import React, { useEffect, useState } from "react";
import axios from 'axios';
import "aos/dist/aos.css";
import "./Aboutcard.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const Aboutcard = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios.get('https://apis.womekiinvestorsclub.co.in/createpost')
      .then(response => {
        console.log(response);
        const filteredPosts = response.data.filter(post => post.category.includes('Womeki Group'));
        const lastThreePosts = filteredPosts.slice(1, 4); 
        setPosts(lastThreePosts);
      })
      .catch(error => console.error('Error fetching posts:', error));
  }, []);

  return (
    <>
      <div className="main-verticals-div">
        <h1 className="heading-verticals">REAL ESTATE</h1>
        <p className="text-verticals">
          Womeki for Every Age, Every Gender, Every Needs.
        </p>
        <div className="container mt-1">
          <div className="row">
            {posts.map((postData, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12 mt-4">
                {/* <Link to={`/RealEstateProduct/${postData._id}`} className="text-dark">  */}
                  <div className="mt-5">
                    <div className="container">
                      <Link to={`/RealEstateProduct/${postData._id}`}className="text-dark">
                        <div className="mini">
                          <div className="container mini-real-card-div">
                            <img
                              className="card-img-top mt-0.3 real-estate-cards-img"
                              src={`https://apis.womekiinvestorsclub.co.in/${postData.files}`}
                              alt="Cardimgcap"
                            />
                          </div>
                          <div className="card-body1">
                            <h1 className="card-titles-card-realestate">
                              {postData.projectname}
                            </h1>
                            <p className="card-titles2-card-realestate">
                              {postData.address}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                {/* </a> */}
              </div>
            ))}
          </div>
        </div>{" "}
        <a className="Button-for-bussiness" href="/BusinessRealestate">
          VIEW MORE
        </a>
      </div>
    </>
  );
};

export default Aboutcard;
