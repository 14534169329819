import React from "react";
import  '../Admin/Admin.css'
const UploadOptions = () => {
  return (
    <div className=" d-flex ms-5 mt-5" >
        <a className="button90" href="/Upload">Update News</a>
      <a className="ms-5 button90" href="/Upload">Upload Blogs</a>
    </div>
  );
};
export default UploadOptions;