import React from 'react'
import './Holidayclub.css'
const Holidayclub4 = () => {
  return (
    <div className='container grid-container mt-5 mb-5'>
    
      <div><img src='./Koti.webp' className='card-img0' alt=''/>
      <h4 className='head1'>Koti Resort </h4></div>
    <div><img src='./hotel-surya-vilas.webp' className='card-img0' alt=''/>
    <h4 className='head1'>Surya Vilas Palace </h4></div>
    <div><img src='./Gaj.webp' className='card-img0' alt=''/>
    <h4 className='head1'>Gaj Retreat </h4></div>
    <div><img src='./hyacinth.webp' className='card-img0' alt=''/>
    <h4 className='head1'>Hyacinth </h4></div>
    </div>
  )
}

export default Holidayclub4