import React from 'react'
import './BlogsHome.css'
import data from './BlogsHome.json'

const BlogsHome = () => {
  return (
    <>
    <div className='blog-main-div'>
     <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-7 col-sm-12 ps-lg-5 d-flex flex-column justify-content-center'>
      <h1 className='ourblog-title'>OUR BLOGS</h1>
      </div>
      <div className='col-md-5 col-sm-12 pe-lg-5 d-flex justify-content-center align-items-center'>
        <img className='blog-hero' src='/blogs-hero-img.webp' alt='ff'/>
      </div>
      </div>
      </div>
      </div>

     <div className='blog-background'>
    <div className='container-fluid pb-5'>
      <div className='row ps-lg-4 pe-lg-4 '>
        {data.Section1.map((NewData, index)=>{
          return(
            <div key={index} className='col-md-4 mt-5  col-sm-12 p-lg-3'>
          <div className='card border-0 rounded-0 blogs-card p-3 '>
            <div className='blog-image-div'>
            <img className='blogs-img ' src={NewData.Image} alt='da'/>
            </div>
            <div className='blog-text-div'>
            <h4 className='blogs-head '>{NewData.Heading}</h4>
<p className='blogs-content'>{NewData.Content}</p>
</div>
<div className='blogs-button'>
<a href={NewData.url} className='text-center'>
<button className='blogs-read-button'>Read More</button></a>
</div>
           </div>
            </div>
          )
        })}
      </div>
    </div>
    </div>
    </>
  )
}

export default BlogsHome
