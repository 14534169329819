import React from 'react'
import Data from '../../../../Components/Newspage.json'
import "./HomeNews.css"
const HomeNews = () => {
  return (
    <div className='d-flex main-home-news-section justify-content-center'>
       {
              Data.Section1.slice(0,2).map((NewData) =>{
                return(
                  <a href='/News' >
                    <div  className='main-home-news-sections ms-1 ' >
                  <img  className="newsimgss  " src={NewData.Image} alt="hh"/>
                  </div>
                  </a>
                )
              })
             }
    </div>
  )
}
export default HomeNews