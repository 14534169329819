import React, { useRef, useState } from 'react';
import "./Query.css";

const Query = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [nameInput, setNameInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [notification, setNotification] = useState('');

  const sendEmail = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    const formData = new FormData(form.current);
    const jsonData = {};
    formData.forEach((value, key) => {
      jsonData[key] = value;
    });

    try {
      const response = await fetch('./connect.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(jsonData)
      });

      const result = await response.json();

      if (result.status === 'success') {
        setNotification('Thank You');
        console.log('Form submitted successfully');
        form.current.reset();
        setNameInput('');
        setPhoneInput('');
      } else {
        setNotification('Error submitting form');
        console.log('Error submitting form');
      }

      setTimeout(() => {
        setIsSubmitted(false);
        setNotification('');
      }, 3000); // Adjust the time as needed
    } catch (error) {
      setNotification('Error submitting form');
      console.log('Error:', error);
    }
  };

  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    if (/^[A-Za-z\s]+$/.test(inputValue) || inputValue === '') {
      setNameInput(inputValue);
    }
  };

  const handleNumberChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    const limitedNumericValue = numericValue.slice(0, 10);
    setPhoneInput(limitedNumericValue);
  };

  return (
    <div className='contmaindiv'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='contact-title-div'>
              <h1 className='conttitle1'>Get In Touch</h1>
            </div>
            <p className='conttext'>Let's Connect and Create Something Amazing Together!</p>
            <form ref={form} onSubmit={sendEmail} id="contact" name="contact" method="post">
              <div className="mb-4">
                <input type="text" className="form-control" placeholder='Enter your Name' value={nameInput} onChange={handleNameChange} name="name" id="name" autoComplete="off" required />
              </div>
              <div className="mb-4">
                <input type="email" className="form-control" placeholder='Enter your Email' name="email" id="email" autoComplete="off" required />
              </div>
              <div className="mb-4">
                <input type="tel" className="form-control" name="tel" id="tel" autoComplete="off" required placeholder='Enter your Contact' value={phoneInput} onChange={handleNumberChange} required />
              </div>
              <div className="mb-4">
                <select className="form-select" name="message">
                  <option selected>How did you find us?</option>
                  <option value="Search Engine">Search Engine</option>
                  <option value="Social Media">Social Media</option>
                  <option value="Blog or Publication">Blog or Publication</option>
                  <option value="Recommended by colleague or friend">Recommended by colleague or friend</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <button type="submit" value="Send" className="contsendbtn">Submit</button>
            </form>
            {notification && (
              <div className={`notification ${isSubmitted ? '' : 'hidden'}`}>
                {notification}
              </div>
            )}
          </div>
          <div className='col-md-6 col-sm-12'>
            <iframe title='bhutani' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28047.162539383997!2d77.37484591083984!3d28.512797099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce9fcbe0273a1%3A0xd8ed754838e2f8a4!2sBhutani%20Alphathum!5e0!3m2!1sen!2sin!4v1700806801377!5m2!1sen!2sin" className="connect" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Query;
