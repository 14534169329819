import React from "react";
import "./WomekiTechSol.css";
const WomekiTechSol = () => {
  return (
    <div className="backgroundContainerz">
      <div className="contentz">
        <h1 className="headlinez">We are launching soon</h1>
      </div>
      <div className="contentImgz">
        <img
          width={120}
          height={120}
          src={'/rocket.webp'}
          className="rocketz"
          alt="p"
        />
      </div>
    </div>
  );
};
export default WomekiTechSol;