import React from 'react'
import data from "./Buildersdata.json";
import './Builders.css';

const Builderspage = () => {
  return (
    <div className='pt-5 pb-5'>
      <h1 className="text-center buildertitle">REAL ESTATE</h1>
      <div className='container mt-md-4 mb-4'>
        <h4 className='build-title2'>Womeki Infra</h4>
        <div className='row bg-white'>
        {data.Section1.map((NewData, index) => {
          return(
            <div key={index} className={`col-lg-4 col-md-6 col-sm-12 mt-5`}>
              <div className={`card border-0 rounded-0 buildercardbox`}>
              <a className="alink" href={NewData.Link}>
                  <img className={`card-img-top businesscardimg`} width={500} height={250} alt='p'  src={NewData.Image} />
                
                  <div className='card-body builder-col-div'>
                    <h5 className={`card-title builerHead`}>{NewData.Heading}</h5>
                    <p className={`card-text builderText`}>{NewData.Content}</p>
                  </div>
                 
                </a>
              </div>
            </div>
          )
        })}
        </div>
      </div>
    </div>
  )
}

export default Builderspage