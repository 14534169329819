import React, { useState } from 'react';
import data from "../AboutUs.json";
import "../AboutUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF,  faLinkedinIn, faInstagram   } from "@fortawesome/free-brands-svg-icons";

const OneVission = () => {
  const [flippedCardIndex, setFlippedCardIndex] = useState(null);

  const handleViewProfileClick = (index) => {
    setFlippedCardIndex(index === flippedCardIndex ? null : index);
  };
  return (
   < div className="aboutmaindiv" >
    {data.Section2.map((NewData, index) => {
        return (
          <div className="container-fluid aboutceomaindiv ">
            <div className="row">
              <div key={index} className="col-md-4 col-sm-12">
                <div className="ceo-img-div">
                  <img className="abtceoimg" src={NewData.Image} alt="ceo" />
                </div>
              </div>
              
              <div className="col-md-8 col-sm-12 d-flex align-items-center justify-content-center">
                <div className=''>
                <div className='p-3'>
              <h2 className="abtceoconts">Our Managing Director & Founder</h2>
              <p className="abtceoconts1">{NewData.Para}</p>
                <p className="abtceocont">{NewData.Content}</p>
                </div>
                <div className="abtceo-social-media d-flex justify-content-between ">
                  <div className="social-circle-fb">
                <a href={NewData.fblink} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className="socialiconfb" icon={faFacebookF} /></a></div>
                <div className="social-circle-linkedin">
                    <a href={NewData.linkedinlink} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon className="socialiconlinkedin" icon={faLinkedinIn} /></a></div>
                    <div className="social-circle-insta">
                    <a href={NewData.instalink} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon  className="socialiconinsta"  icon={faInstagram} /></a></div> 
                </div>
              </div>
              </div>
            </div>
            
          </div>
        )
      })}
       <div className="abtteambg  mt-md-5">
        <div className="container ourteammain pt-5 pb-5">
            <div className="row mar">
            <h1 className="teamtitle">One Vision One Team</h1>
{data.Section3.slice(2).map((NewData, index) => {
      return(
          
        <div key={index} className="col-lg-4  col-md-6 d-flex align-items-center justify-content-center">
        <div>
        <div className={`card ourteamcarddiv border-0 ${flippedCardIndex === index ? 'flipped' : ''}`}>
            <div className="card-inner">
              <div className="card-front">
                <img src={NewData.Image} className="abtteamimg" alt="kk" />
                <div className="abtteam-text">
                  <div>
                  <h5 className="card-title abtteamhead">{NewData.Heading}</h5>
                  <p className="card-text abtteamcontent">{NewData.Content}</p>
                  </div>
                  </div>
              </div>
              <div className="card-back d-flex flex-column align-items-center justify-content-center"  onClick={() => handleViewProfileClick(index)}>
                  <div className="abtceo-social-media1 pb-3">
                    <h5 className=" abtteamhead">{NewData.Heading}</h5>
                    <p className=" abtteamcontent">{NewData.Text} </p>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    
      )
    })} 
    </div> 
    </div>
   </div>
   </div>
  );
};
export default OneVission;