import React from 'react'
import data from "./Techcard4.json"
import "./Techcard.css"
const Techcard4 = () => {
  return (
   
    <div className='techpagediv'>
    {data.Section1.map((NewData,index) => {
      return(
        <div key={index}>
          <img className='tech-cards-img mt-2' src={NewData.Image} alt="img" />
          <div className='container mt-4'>
            <h2 className="techhead">{NewData.Heading}</h2>
            <p className="techcontent">{NewData.Content}</p>
          </div>
        </div>  
      )
    })}
     <div className='container mt-5'>
     {data.Section2.map((NewData,index) => {
      return(
      <div key={index} className="row mt-5 techcardrow">
        <div className='col-md-4 col-sm-12'>
          <img className='tech-img'  alt='aa' src={NewData.Image}/>
        </div>
        <div className='col-md-8 col-sm-12  d-flex flex-column justify-content-center align-items-center'>
        <p className="techcontent">{NewData.Content}</p>
        </div>
      </div>
       )
      })}
{data.Section3.map((NewData,index) => {
      return(
      <div key={index} className='row mt-5 d-flex flex-column-reverse flex-md-row'>
        <div className='col-md-8 col-sm-12  d-flex flex-column justify-content-center align-items-center'>
        <p className="techcontent">{NewData.Content}</p>
        </div>
        <div className='col-md-4 col-sm-12'>
          <img className='tech-img' alt='aa' src={NewData.Image}/>
        </div>
      </div>
       )
      })}
{data.Section4.map((NewData,index) => {
      return(
        <div key={index} className=' mt-md-5'>
        <p className="techcontent">{NewData.Content}</p>
        </div>
       )
      })}
      </div>
    </div>
  )}
export default Techcard4