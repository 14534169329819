import React from "react";
import "./BusinessRealestate.css";
import data from "./BusinessRealestate.json";

const BusinessRealestate = () => {

  return (
    <div className='pt-5 pb-5'>
      <h1 className="text-center realBSRdiv">REAL ESTATE</h1>
      <div className='container mt-md-4 mb-4'>
        <h4 className='real-title'>Womeki Investors Club</h4>
        <div className='row bg-white'>
        {data.Section1.map((NewData, index) => {
          return(
            <div key={index} className={`col-lg-4 col-md-6 mt-5 d-flex align-items-center justify-content-center`}>
              <div className={`card border-0 rounded-0 businesscardbox`}>
              <a className="alink" href={NewData.Link}>
                  <img className={`card-img-top businesscardimg`}  alt='p'  src={NewData.Image} />
                  <div className='card-body business-col-div'>
                    <h5 className={`card-title Head`}>{NewData.Heading}</h5>
                    <p className={`card-text Text`}>{NewData.Content}</p>
                  </div>
                </a>
              </div>
            </div>
          )
        })}
        </div>
      </div>
    </div>
  );
};

export default BusinessRealestate;
