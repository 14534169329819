import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import data from './Brandstory.json'
import './Brandstory.css'
const Brandstory = () => {
  return (
    <>
    <div className='container-fluid d-none d-sm-block  meetmaindiv'>
    <div className='row p-5'>
    <h1 className='meet-main-head mb-5'>"3rd Dada Saheb Phalke Award Winner" </h1>
      <div className='col-6 '>
        <h4 className='meet-title '>Mr. Gaurav k. Singh<br/> </h4>
        <p className='meet-text  mt-lg-5'>Mr. Gaurav K. Singh received award for Youngest Tycoon in Real estate by Mr. P.C Bairwa -Deputy CM, Rajasthan in Dubai. Ms. Neha Chandra recieved award for Womeki holiday club by Ex. CM, Uttarakhand. Another Proud Moment for Womeki Group. May this award be a symbol of your incredible achievements and the beginning of even greater things.</p>
      
      </div>
      <div className='col-6'>
        <img className='hero-meet-img' src='./AB.webp' alt='ff'/>
      </div>
    </div>
    <div className='row pt-5'>
  
{data.Section1.map((NewData, index)=>{
  return(
    <div key={index} className='col-4 mt-lg-5 mt-md-2'>
    
    <img className='imgs-meet1' src={NewData.Image} alt='ff'/>
    
    </div>
  )
})}
  

    </div>
    <div className='row pt-5'>
  {data.Section2.map((NewData, index)=>{
    return(
      <div key={index} className='col-4 mt-lg-5 mt-md-2'>
     
      <img className='imgs-meet1' src={NewData.Image} alt='ff'/>
      </div>
    
    )
  })}
      </div>
    </div>

<div className='container-fluid pt-5 mb-4 d-sm-none'>
<h1 className='meet-main-head'>"3rd Dada Saheb Phalke Award Winner"</h1>
    <div className='row'>
      <div className='col-12'>
    <h4 className='meet-title '>Mr. Gaurav k. Singh</h4>
   
   <p className='meet-text'>Mr. Gaurav K. Singh received award for Youngest Tycoon in Real estate by Mr. P.C Bairwa -Deputy CM, Rajasthan in Dubai. Ms. Neha Chandra recieved award for Womeki holiday club by Ex. CM, Uttarakhand. Another Proud Moment for Womeki Group. May this award be a symbol of your incredible achievements and the beginning of even greater things.</p>
   </div>
   <div className='col-12'>
   <img className='hero-meet-img' src='./AB.webp' alt='ff'/>
   </div>
    </div>
    {/* <h4 className='meet-head mb-4 mt-3'>Vice Prime Minister of Timor Leste</h4> */}
    <Swiper
          className=" Carouseldiv "
          modules={[Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          speed={1000}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          >
    
    <div className='row'>


    {data.Section1.map((NewData, index)=>{
  return(
    <SwiperSlide>
       <div className='meet-images'>
    <img className='imgs-meet1' src={NewData.Image} alt='ff'/>
    </div>
      </SwiperSlide>
       )
      })}
    </div>
    </Swiper>
    <Swiper
          className=" Carouseldiv "
          modules={[Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          speed={1000}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          >
    <div className='row'>
   
    {data.Section2.map((NewData)=>{
  return(
    <SwiperSlide>
       <div className='meet-images'>
    <img className='imgs-meet1' src={NewData.Image} alt='ff'/>
    </div>
      </SwiperSlide>
       )
      })}
    </div>
    </Swiper>
    
    </div>
  
    </>
  )
}

export default Brandstory