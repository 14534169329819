import React from 'react';
import data from './Cowrking.json';
import './Coworking.css'
const Coworking = () => {
  return (
    <div className='realpagediv'>
    {data.Section1.map((NewData,index) => {
      return(
        <div key={index}>
          <img className='Cardreaslestateimg mt-2' src={NewData.Image} alt="img" />
          <div className='container mt-4'>
            <h2 className="realestatehead">{NewData.Heading}</h2>
            <p className="realestatecontent">{NewData.Content}</p>
          </div>
        </div> 
      )
    })}
     <div className='container mt-5'>
     {data.Section2.map((NewData,index) => {
      return(
      <div key={index} className="row mt-5 realestatecardrow">
        <div className='col-md-4 col-sm-12'>
          <img className='cowork-img' height={300} width={400} alt='aa' src={NewData.Image}/>
        </div>
        <div className='col-md-8 col-sm-12  d-flex flex-column justify-content-center align-items-center'>
          <p className="realestatecontent">{NewData.Content}</p>
        </div>
      </div>
       )
      })}
{data.Section3.map((NewData,index) => {
      return(
      <div key={index} className='row mt-5 d-flex flex-column-reverse flex-md-row'>
        <div className='col-md-8 col-sm-12  d-flex flex-column justify-content-center align-items-center'>
        <p className="realestatecontent">{NewData.Content}</p>
        </div>
        <div className='col-md-4 col-sm-12'>
          <img  className='cowork-img' height={300} width={400} alt='aa' src={NewData.Image}/>
        </div>
      </div>
       )
      })}
{data.Section4.map((NewData,index) => {
      return(
        <div key={index} className=' mt-md-5'>
        <p className="realestatecontent ">{NewData.Content}</p>
        </div>
       )
      })}
      </div>
      <div className=' visitdiv'>
        <div className='container  d-flex justify-content-between align-items-center p-5'>
          <p className='visit-text'>Visit the website for Details</p>
        <a href={'https://www.wic.org.in/'}  target="_blank" rel="noopener noreferrer" >
      <button className='visitedubutton'>Visit Now</button></a>
      </div>
      </div>
    </div>
  )
}
export default Coworking