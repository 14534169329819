import React from "react";
import './Womeki International.css'
import data from './Womeki International.json'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import {Media, Video } from '@vidstack/player-react';

const WomekiInternational = () => {
  return (
    <div className='container pt-5 pb-5'>
      <div className='row pt-4'>
        <div className='col-12'>
        <h1 className='international-head mt-5'>A Milestone in International Expansion</h1>
          <img className='womeki-inter-img' src='/news4.webp' alt='ds' />
       
          <p className='international-content'>Womeki Group, a dynamic and innovative company in the real estate industry,
            proudly announces its successful entry into the international arena. Under the
            visionary leadership of Mr. Gaurav K Singh, the Managing Director of Womeki Group, the company has
            not only expanded its global presence but has also built a significant partnership with the
            Republic of Cambodia. In a historic move, Womeki Group signed a Memorandum of Understanding (MOU)
            with the Cambodia Properties Limited, marking a momentous step towards fostering
            economic ties between the two nations. 
          </p>
          <p className='international-content'>The MOU Signing Ceremony took place between
            Womeki Group and Cambodia Properties Limited on 30th November 2023. This ceremony
            encompasses various aspects of collaboration, including trade, technology transfer, and
            socio-economic development. Mr. Gaurav K Singh expressed his enthusiasm about this strategic alliance, stating, "This
            collaboration is a testament to Womeki Group's commitment to global progress and
            collaboration. Cambodia presents an exciting opportunity for mutual growth, and we are
            eager to contribute to the nation's development.</p> 

          <p className='international-content'>The international trip, led by Mr. Gaurav K Singh, provided a platform for productive
            discussions and sharing of ideas between the Womeki Group team and Cambodia's
            leadership. The Managing Director participated in important meetings, exploring ways to work together
            and finding areas where Womeki Group's expertise and innovation can help Cambodia
            achieve its economic goals. Reflecting on the success of the trip, Mr. Singh remarked, "The meetings were extremely
            productive, and there is a shared vision between Womeki Group and Cambodia to
            create a positive impact on both our nations.
          </p>
          <p className='international-content'>We are excited about the possibilities
            that lie ahead. Womeki Group's international expansion is a strategic move to diversify its portfolio and
            tap into emerging markets. The company's commitment to excellence, innovation, and
            sustainable business practices has positioned it as a leader in its industry, gaining
            recognition on a global scale. As Womeki Group embarks on this new chapter of international collaboration, the
            company remains dedicated to its core values of integrity, quality, and social responsibility.
            The MOU with Cambodia Properties Limited is just the beginning of what promises to be a
            mutually beneficial relationship, and Womeki Group looks forward to contributing to the
            growth and prosperity of the Republic of Cambodia".</p>
        </div>
      </div>

      <Swiper
        className=" Carouseldiv "
        modules={[Pagination, Autoplay, Navigation]}
        spaceBetween={30}
        slidesPerView={3}
        loop={true}
        speed={1000}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        // pagination={{ clickable: true }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 40,
          },

          480: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 100,
          },
        
          1024: {
            slidesPerView: 3,
            spaceBetween: 120,
          }
        }}
      >

        {data.Section1.map((NewData, index) => {
          return (
            <SwiperSlide>
              <div className="tour-img-div">
              <img className="inter-carousel-img" src={NewData.Image} alt="ss" />
              </div>
            </SwiperSlide>
          )
        })}

      </Swiper>
<div className="tourvideodiv mt-5">
  <h4 className="video-title1">GLOBAL INSIGHTS</h4>
 <h6 className="video-title2"> Watch Now</h6>
      <Media>
  <Video className="video" loading="visible" poster="./poster.jpg" controls preload="true">
    <video  loading="visible" poster="./poster.jpg" src="./persentation.mp4" preload="none" data-video="0" controls />
  </Video>
</Media>
</div>
    </div>
  )
}

export default WomekiInternational
