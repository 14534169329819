import React from 'react'
import './BusinessMeet.css'
import data from './BusinessMeet.json'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css/scrollbar";
import "swiper/css";

const BusinessMeet = () => {
  return (
    <>
    <div className='container-fluid  meetmaindiv'>
    <div className='row p-5'>
    <h1 className='meet-main-head mb-5'>Womeki's Overseas Endeavor - MOU in the Kingdom of Cambodia</h1>
      <div className='col-6'>
        <h4 className='meet-title '>We've Inked the Future <br/> with <br/>Memorandum of Understanding (MOU)</h4>
        <p className='meet-text  mt-lg-5'>The international trip, led by Mr. Gaurav K Singh, provided a platform for productive discussions and sharing of ideas between the Womeki Group team and Cambodia's leadership. </p>
      
      </div>
      <div className='col-6'>
        <img className='hero-meet-img' src='./meet-img5.webp' alt='ff'/>
      </div>
    </div>
    <div className='row pt-5'>
  
{data.Section1.map((NewData, index)=>{
  return(
    <div key={index} className='col-lg-4 col-md-6 col-sm-12  mt-5'>
    
    <img className='imgs-meet' src={NewData.Image} alt='ff'/>
    
    </div>
  )
})}
  
  {data.Section3.map((NewData, index)=>{
  return(
    <div key={index} className='col-lg-4 col-md-6 col-sm-12 mt-lg-5 mt-md-2'>
    
    <img className='imgs-meet-second' src={NewData.Image} alt='ff'/>
    
    </div>
  )
})}

    </div>
    <div className='row pt-5'>
  <h4 className='meet-title mt-5'>DELEGATES OF TIMOR LESTE </h4>
  {data.Section2.map((NewData, index)=>{
    return(
      <div key={index} className='col-lg-4 col-md-6 col-sm-12 mt-lg-5 mt-md-2'>
     
      <img className='imgs-meet' src={NewData.Image} alt='ff'/>
      </div>
    
    )
  })}
      </div>
    </div>

<div className='container-fluid pt-5 mb-4 meetmobdiv'>
<h1 className='meet-main-head'>Womeki's Overseas Endeavor - MOU in the Kingdom of Cambodia</h1>
    <div className='row'>
      <div className='col-12'>
    <h4 className='meet-title '>We've Inked the Future with a Memorandum of Understanding (MOU)</h4>
   
   <p className='meet-text'>The international trip, led by Mr. Gaurav K Singh, provided a platform for productive discussions and sharing of ideas between the Womeki Group team and Cambodia's
leadership. </p>
   </div>
   <div className='col-12'>
   <img className='hero-meet-img' src='./meet-img5.webp' alt='ff'/>
   </div>
    </div>
    {/* <h4 className='meet-head mb-4 mt-3'>Vice Prime Minister of Timor Leste</h4> */}
    <Swiper
          className=" Carouseldiv "
          modules={[Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          speed={1000}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            480: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            767: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
          
          }}
          >
    
    <div className='row'>


    {data.Section1.map((NewData, index)=>{
  return(
    <SwiperSlide>
       <div key={index} className='meet-images'>
    <img className='imgs-meet' src={NewData.Image} alt='ff'/>
    </div>
      </SwiperSlide>
       )
      })}
    </div>
    </Swiper>

    <h4 className='meet-title mt-5'>DELEGATES OF TIMOR LESTE </h4>
    <Swiper
          className=" Carouseldiv "
          modules={[Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          speed={1000}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            480: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            767: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
          
          }}
          >
    <div className='row'>
   
    {data.Section2.map((NewData)=>{
  return(
    <SwiperSlide>
       <div className='meet-images'>
    <img className='imgs-meet' src={NewData.Image} alt='ff'/>
    </div>
      </SwiperSlide>
       )
      })}
    </div>
    </Swiper>
    
    </div>
  
    </>
  )
}

export default BusinessMeet
