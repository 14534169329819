import React from 'react'
import './Holidayclub.css'
const Holidayclub1 = () => {
  return (
    <div className='container mt-5'>
            <div className='row'>
                <div className='col-md-6 col-sm-12'>
                <img src='./holidaypopup.webp' className='img2' alt=''/>
                </div>
                <div className='col-md-6 col-sm-12'>
                <h2 className='head'>Our Vision</h2>  
                <p className='para'>Our vision is to develop sustainable and responsible tourism destinations in India and Abroad which requires a holistic approach that considers environmental conservation, socio-cultural preservation and economic prosperity. We aims to minimize negative environmental impacts by promoting conservation practices, reducing carbon footprint, and preserving natural resources. This involves implementing eco-friendly infrastructure, waste management systems, and renewable energy solutions.</p>
                <p className='para'>We will be encouraging responsible tourism which emphasizes respect for local cultures, traditions, and communities. It involves engaging with indigenous populations, supporting local businesses, and providing opportunities for cultural exchange.We also vision to generate income and employment opportunities for local residents while promoting equitable distribution of benefits.</p>
                <p className='para'>We believe in prioritizing sustainability, responsibility, and inclusivity, through which we can create tourism experiences that enrich both visitors and host communities while safeguarding natural and cultural heritage for future generations.</p>
                </div>
                </div>
        </div>
  )
}

export default Holidayclub1