import './App.css';
import React from 'react';
import HomePage from './Components/HomePage';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Business from './Components/Businesspage';
import AboutUs from './Components/pages/AboutPage/AboutUs';
import CareerHome from './Components/Career/CareerHome';
import CareerCard1 from './Components/Career/CareerCard1/CareerCard1';
import CareerCard2 from './Components/Career/CareerCard2/CareerCard2';
import CareerCard3 from './Components/Career/CareerCard3/CareerCard3';
import BlogsHome from './Components/pages/Blogs/BlogsHome';
import BlogpageOne from './Components/pages/Blogs/Blogpage/BlogpageOne';
import BlogpageTwo from './Components/pages/Blogs/Blogpage/BlogpageTwo';
import Query from './Components/pages/ContactPage/Query';
import AdminLogin from './Components/pages/Admin/AdminLogin';
import Upload from './Components/pages/Admin/Upload';
import TempNews from './Components/TempNews';
import DetailsPage1 from './Components/pages/AboutPage/DetailsPage/DetailsPage1'
import DetailsPage2 from './Components/pages/AboutPage/DetailsPage/DetailsPage2'
import DetailsPage3 from './Components/pages/AboutPage/DetailsPage/DetailsPage3'
import DetailsPage4 from './Components/pages/AboutPage/DetailsPage/DetailsPage4'
import Ceo from './Components/pages/AboutPage/CEO/Ceo';
import OneVission from './Components/pages/AboutPage/OneVission/OneVission';
import SustainabilityPage from './Components/Sustainability/SustainabilityPage';
import BusinessEducation from './Components/pages/Business/BusinessEducation/BusinessEducation';
import BusinessRealestate from './Components/pages/Business/BusinessRealestate/BusinessRealestate';
import BusinessTech from './Components/pages/Business/BusinessTech/BusinessTech';
import EducationCard1 from './Components/pages/Business/BusinessEducation/Businesseducationcards/EducationCard1'
import EducationCard2 from './Components/pages/Business/BusinessEducation/Businesseducationcards/EducationCard2'
import EducationCard3 from './Components/pages/Business/BusinessEducation/Businesseducationcards/EducationCard3'
import EducationCard4 from './Components/pages/Business/BusinessEducation/Businesseducationcards/EducationCard4'
import EducationCard5 from './Components/pages/Business/BusinessEducation/Businesseducationcards/EducationCard5'
import EducationCard6 from './Components/pages/Business/BusinessEducation/Businesseducationcards/EducationCard6'
import Realestatecard1 from './Components/pages/Business/BusinessRealestate/Businessrealestatecards/Realestatecard1';
import Realestatecard2 from './Components/pages/Business/BusinessRealestate/Businessrealestatecards/Realestatecard2';
import Realestatecard3 from './Components/pages/Business/BusinessRealestate/Businessrealestatecards/Realestatecard3';
import Realestatecard4 from './Components/pages/Business/BusinessRealestate/Businessrealestatecards/Realestatecard4';
import Realestatecard5 from './Components/pages/Business/BusinessRealestate/Businessrealestatecards/Realestatecard5';
import Realestatecard6 from './Components/pages/Business/BusinessRealestate/Businessrealestatecards/Realestatecard6';
import Techcard1 from './Components/pages/Business/BusinessTech/BusinessTechcards/Techcard1';
import Techcard2 from './Components/pages/Business/BusinessTech/BusinessTechcards/Techcard2';
import Techcard3 from './Components/pages/Business/BusinessTech/BusinessTechcards/Techcard3';
import Techcard4 from './Components/pages/Business/BusinessTech/BusinessTechcards/Techcard4';
import Techcard5 from './Components/pages/Business/BusinessTech/BusinessTechcards/Techcard5';
import Techcard6 from './Components/pages/Business/BusinessTech/BusinessTechcards/Techcard6';
import BlogpageThree from './Components/pages/Blogs/Blogpage/BlogpageThree';
import WomekiTechSol from './Components/WomekiTechSol/WomekiTechSol';
import DetailPage5 from './Components/pages/AboutPage/DetailsPage/DetailPage5';
import DetailPage6 from './Components/pages/AboutPage/DetailsPage/DetailPage6';
import DetailPage7 from './Components/pages/AboutPage/DetailsPage/DetailPage7';
import Coworking from './Components/pages/Business/BussinessCo-WorkingSpaces/Coworking';
import Infra from './Components/pages/Business/Infra/Infra';
import WomekiInternational from './Components/pages/Womeki International/WomekiInternational';
import BusinessMeet from './Components/pages/Womeki International/BusinessMeet/BusinessMeet';
import ProjectPage from './Components/pages/Business/BusinessRealestate/ProjectPage';
import Holidayclub from './Components/pages/Holidayclub/Holidayclub';
import Brandstory from './Components/Brandstory/Brandstory';
import Builderspage from './Components/Builders/Builderspage';
import Brandstory1 from './Components/Brandstory/Brandstory1';

 function App() {
  return (
    <div className="App">
       <Router>
        <Navbar/>
       <Switch>
        <Route exact path='/' component={HomePage}/>
        <Route  path='/business' component={Business}/>
        <Route  path='/about' component={AboutUs}/>
        <Route  path='/Career' component={CareerHome} />
        <Route path='/RealEstate' component={CareerCard1} />
        <Route path='/ITsolution' component={CareerCard2} />
        <Route path='/education' component={CareerCard3} />
        <Route  path='/blog' component={BlogsHome}/>
        <Route  path='/blogrealestate' component={BlogpageOne}/>
        <Route  path='/blogtech' component={BlogpageTwo}/>
        <Route path='/blogeducation' component={BlogpageThree}/>
        <Route  path='/contact' component={Query}/>
        <Route path='/admin' component={AdminLogin}/>
        <Route path='/Upload' component={Upload}/>
        <Route path='/News' component={TempNews} />
        <Route path='/GauravTonger' component={DetailsPage1} />
        <Route path='/RohitKhari' component={DetailsPage2} />
        <Route path='/ParasGoyal' component={DetailsPage3} />
        <Route path='/AnkitTonger' component={DetailsPage4} />
        <Route path='/CeoMessage' component={Ceo} />
        <Route path='/OneVission' component={OneVission} />
        <Route path='/SustainabilityPage' component={SustainabilityPage} />
        <Route path='/BusinessRealestate' component={BusinessRealestate}/>
        <Route path='/BusinessEducation' component={BusinessEducation}/>
        <Route path="/Co-Working" component={Coworking}/>
        <Route path="/Infra" component={Infra} />
        <Route path='/Tech' component={BusinessTech}/>
        <Route path='/EducationCard1' component={EducationCard1} />
        <Route path='/EducationCard2' component={EducationCard2} />
        <Route path='/EducationCard3' component={EducationCard3} />
        <Route path='/EducationCard4' component={EducationCard4} />
        <Route path='/EducationCard5' component={EducationCard5} />
        <Route path='/EducationCard6' component={EducationCard6} />
        <Route path="/Khatu-Shyam" component={Realestatecard1}  />
        <Route path="/Paras-Avenue" component={Realestatecard2}  />
        <Route path="/Etherea" component={Realestatecard3}  />
        <Route path="/Clark" component={Realestatecard4} />
        <Route path="/Sikka" component={Realestatecard5} />
        <Route path="/Nexuria" component={Realestatecard6} />
        <Route path="/Revolutionizing" component={Techcard1} />
        <Route path="/Digital_Age" component={Techcard2} />
        <Route path="/Innovation" component={Techcard3} />
        <Route path="/Solutions" component={Techcard4} />
        <Route path="/Embrancing" component={Techcard5} />
        <Route path="/Expanding" component={Techcard6} />
        <Route path="/WomekiTechSolutions" component={WomekiTechSol} />    
        <Route path="/KartarSingh" component={DetailPage5} />
        <Route path="/JagatSingh" component={DetailPage6} />
        <Route path="/VijayPalSingh" component={DetailPage7} />
        <Route path='/womekiInternational' component={WomekiInternational} />
        <Route path='/BusinessMeet' component={BusinessMeet}/>
        <Route path='/RealEstateProduct/:_id' component={ProjectPage}/>
        <Route path='/HolidayClub' component={Holidayclub}/>
        <Route path='/BrandStory' component={Brandstory}/>
        <Route path='/BrandStory1' component={Brandstory1}/>
        <Route path='/Builders' component={Builderspage}/>
      </Switch>
      <Footer/>
      </Router>
    </div>
  );
}
export default App;