import React from "react";
import data from "./WomekiEvents.json";
import "./HomeBlog.css";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const WomekiEvents = () => {
  return (
    <div className="carouselbackground mt-5 mb-5" >
      <div className="container" >     
        <div className="row" >
        <h1 className="carousel-coming-soon-heading ">WOMEKI EVENTS</h1>
          <Swiper className="carousel-main-div"
            breakpoints={{
              0: { slidesPerView: 1 },
              480: {
                slidesPerView: 2,
                spaceBetween: 160
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 150
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50
              }
            }}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,

            }}
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={0}
            slidesPerView={3}
            navigation
          >
              {
                data.Section1.map((NewData) =>{
                  return(
                    <SwiperSlide>
                    <>
                      <img className="img-carousel-new " src={NewData.Url} alt="l" />
                    </>
                    </SwiperSlide>
                  )
                })
              }
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default WomekiEvents;