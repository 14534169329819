import React from 'react'
import './Holidayclub.css'
const Holidayclub2 = () => {
  return (
    <div className='container mt-5'>
        <div className='row flexii'> 
    <div className='col-md-6 col-sm-12 '>
    <h2 className='head'>Mission</h2>
    <p className='para'>In FY24/25, our mission is to establish two new captivating
tourism spots, blending natural beauty with cultural richness, ensuring
sustainability and responsible practices. Concurrently, we also aim to empower ten hotels, providing them with resources for eco-friendly initiatives, staff training, and infrastructure enhancements. By fostering partnerships between local communities, and businesses we strive to create immersive experiences for visitors while promoting economic growth and preserving our environment and heritage. Through these endeavors, we envision a vibrant and resilient tourism sector that enriches lives and fosters sustainable development for generations to come.    </p>
    </div>
    <div className='col-md-6 col-sm-12'>
    <img src='./Holidayimg3.webp' className='img3 ' alt=''/>
    </div>
    </div>
</div>
  )
}

export default Holidayclub2