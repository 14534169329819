import React, { useState, useEffect, Suspense } from "react";
import "react-quill/dist/quill.snow.css";
import '../Admin/Admin.css';
const ReactQuill = React.lazy(() => import("react-quill"));
function Upload() {
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [files, setFiles] = useState("");
  const [ SetRedirect] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const body = document.querySelector("body");
      body.style.backgroundColor = "white";
    }
  }, []);
  async function createNewPost(ev) {
    ev.preventDefault();
    const data = new FormData();
    data.set("title", title);
    data.set("summary", summary);
    data.set("content", content);
    data.set("file", files[0]);
    const response = await fetch("http://localhost:4000/post", {
      method: "POST",
      body: data,
      credentials: "include",
    });
    if (response.ok) {
      SetRedirect(true);
    }
  }
  return (
    <div className="pt-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <form className="login pt-5" onSubmit={createNewPost}>
              <h3 className="text-center mb-4">Upload Data</h3>
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  value={title}
                  onChange={(ev) => setTitle(ev.target.value)}
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Summary"
                  value={summary}
                  onChange={(ev) => setSummary(ev.target.value)}
                />
              </div>
              <div className="form-group mb-3">
                <input type="file" onChange={(ev) => setFiles(ev.target.files)} />
              </div>
              <Suspense fallback={<div>Loading editor...</div>}>
                <ReactQuill value={content} onChange={setContent} />
              </Suspense>
              <div className="form-group text-center">
                <button className="mt-3 Explorebtn" style={{ marginTop: "5px" }}>Create post</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Upload;