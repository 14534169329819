import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './BusinessRealestate.css'

const ProjectPage = () => {
  const { _id } = useParams();
  const [product, setProduct] = useState(null);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    axios.get('https://apis.womekiinvestorsclub.co.in/createpost')
      .then(response => {
        const matchedProduct = response.data.find(item => item._id === _id);
        setProduct(matchedProduct);
      })
      .catch(error => {
        console.error('Error fetching project data:', error);
      });
  }, [_id]);

  const toggleContent = () => {
    setShowMore(!showMore);
  };
  const renderContent = () => {
    if (!product) return <div className="loader">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="circle"></div>
</div>
;

    const { content } = product;
    const truncatedContent = content.slice(0, 550);

    return (
      <div className='container'>
        <img className={`real_hero_img`} alt='p' src={`https://apis.womekiinvestorsclub.co.in/${product.files}`} />
        <h2 className='mt-4 productheading'>{product.projectname}</h2>
        <p className='productsubheading mt-2 mb-3'>{product.address}</p>
        <div className='productcontent'>
          <p dangerouslySetInnerHTML={{__html: showMore ? content : truncatedContent}}></p>
          <div className='d-flex justify-content-center'>{!showMore && <button onClick={toggleContent} className="seebutton mb-3">See More</button>}</div>
        </div>
      </div>
    );
  };

  return (
    <div className=' mt-5'>
      {renderContent()}
    </div>
  );
};

export default ProjectPage;
