
import React from "react";
import data from "./Business.json";
import "./Business.css";


const Business = () => {
  
  return (
    <div className='businessmaindiv pb-5'>
        {data.Section1.map((NewData, index) => {
          return(
            <div key={index} className="businesssection1div">
            <div className='container  businesscontdiv '>
              <div className='row d-flex align-items-center justify-content-center'>
              <div className='col-md-6 col-sm-12'>
              <p className='businessContent'>{NewData.Content}</p>
              </div>
              <div className='col-md-6 col-sm-12'>
             <img className="bgbusinessimg" src={NewData.Image} alt="imgi"/>
             </div>
              </div>
             </div>
            </div>
          )
        })}
       <div className=" businesscontainersec2 container mt-5">
        <h5 className="busintitleh">SECTORS</h5>
        <div className="row">
        {data.Section2.map((NewData, index) => {
          return(
           <>
           <div key={index} className="col-md-7 col-sm-12 mt-5  d-flex flex-column justify-content-center jkl">
           
           <h5 className="card-title businesscardhead">{NewData.Heading}</h5>
        
           <p className="card-text businesscardcontent">{NewData.Content}</p>
           <a href={NewData.Link} >
            <div className="btn-div">
           <button className="btnbusin">{NewData.Buttontext}</button></div></a>
           </div>
           <div className="col-md-5 col-sm-12 mt-5 ">
            <div className="businesscardimgdiv d-flex align-items-center justify-content-center">
           <img src={NewData.Image} className=" busicardimgs" alt="crd"/>
           </div>
       </div>
       </> 
          )
        })}
        </div>
        
        <div className="row d-flex flex-column-reverse flex-md-row">
        {data.Section3.map((NewData, index) => {
          return(
           <>
             
             <div className="col-md-5 col-sm-12 mt-5">
              <div className="businesscardimgdiv d-flex align-items-center justify-content-center">
            <img src={NewData.Image} className=" busicardimgs" alt="crd"/>
            </div>
            </div>
            <div key={index} className="col-md-7 col-sm-12 mt-5 d-flex flex-column justify-content-center">
           <h5 className="card-title businesscardhead">{NewData.Heading}</h5>
           <p className="card-text businesscardcontent">{NewData.Content}</p>
           <a href={NewData.Link} >
           <div className="btn-div">
           <button className="btnbusin">{NewData.Buttontext}</button></div></a>
           </div>
       </> 
          )
        })}
       </div>
       
          <div className="row">
         {data.Section4.map((NewData, index) => {
          return(
           <>
        
       <div key={index} className="col-md-7 col-sm-12 mt-5 d-flex flex-column justify-content-center ">
           
           <h5 className="card-title businesscardhead">{NewData.Heading}</h5>
        
           <p className="card-text businesscardcontent">{NewData.Content}</p>
           <a href={NewData.Link} >
            <div className="btn-div">
           <button className="btnbusin">{NewData.Buttontext}</button></div></a>
           </div>
           
           <div className="col-md-5 col-sm-12 mt-5   ">
            <div className="businesscardimgdiv d-flex align-items-center justify-content-center">
           <img src={NewData.Image} className=" busicardimgs" alt="crd"/>
           </div>
       </div>
       </> 
          )
        })}
        
            </div>
            <div className="row d-flex flex-column-reverse flex-md-row">
        {data.Section5.map((NewData, index) => {
          return(
           <>
             
             <div className="col-md-5 col-sm-12 mt-5">
              <div className="businesscardimgdiv d-flex align-items-center justify-content-center">
            <img src={NewData.Image} className=" busicardimgs" alt="crd"/>
            </div>
            </div>
            <div key={index} className="col-md-7 col-sm-12 mt-5 d-flex flex-column justify-content-center">
           <h5 className="card-title businesscardhead">{NewData.Heading}</h5>
           <p className="card-text businesscardcontent">{NewData.Content}</p>
           <a href={NewData.Link} >
           <div className="btn-div">
           <button className="btnbusin">{NewData.Buttontext}</button></div></a>
           </div>
       </> 
          )
        })}
       </div>
         </div>
         </div>

  )
}
export default Business
