import React from 'react'
import data from '../../../pages/Blogs/BlogsHome.json'
import './Homeblogs.css'
const HomeBlogs = () => {
  return (
    <div> 
    <div className="gallerydiv">
      <div className='row d-flex justify-content-center'>
    {data.Section1.slice(1,2).map((NewData, index)=>{
          return(
            <div key={index} className='col-md-4 mt-5  col-sm-12 p-lg-3'>
          <div className='card border-0 rounded-0 blogs-homr-card p-3'>
            <img className='blogs-img ' src={NewData.Image} alt='da'/>
            <h4 className='blogs-head mt-3'>{NewData.Heading}</h4>
<p className='blogs-content'>{NewData.Content}</p>
<a href={NewData.url} className='text-center'>
<button className='blogs-read-button'>Read More</button></a>
           </div>
            </div>
          )
        })}
         </div>
         </div>
     </div>
  )
}
export default HomeBlogs