import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import data from './Brandstory.json'
import './Brandstory.css'
const Brandstory1 = () => {
  return (
    <>
    <div className='container-fluid d-none d-sm-block  meetmaindiv'>
    <div className='row p-5'>
    <h1 className='meet-main-head mb-5'>"Global high school Cricket cup Present life time achievement award" </h1>
      <div className='col-6 '>
        <h4 className='meet-title '>Mr. Shiv k. Singh</h4>
        <p className='meet-text  mt-lg-5'>"Our esteemed Director, Mr. Shiv K. Singh, received the prestigious Lifetime Achievement Award from the Global High School Cricket Cup. Presented by renowned international cricketers Dav Whatmore and Saeed Ajmal, this honor recognizes Mr. Singh's outstanding contributions to cricket and his steadfast commitment to developing young talent."</p>
      
      </div>
      <div className='col-6'>
        <img className='hero-meet-img' src='./shivsirevent-3.webp' alt='ff'/>
      </div>
    </div>
    <div className='row pt-5'>
  
{data.Section3.map((NewData, index)=>{
  return(
    <div key={index} className='col-4 mt-lg-5 mt-md-2'>
    
    <img className='imgs-meet1' src={NewData.Image} alt='ff'/>
    
    </div>
  )
})}
  

    </div>
    </div>

<div className='container-fluid pt-5 mb-4 d-sm-none'>
<h1 className='meet-main-head'>"Global high school Cricket cup Present life time achievement award"</h1>
    <div className='row'>
      <div className='col-12'>
    <h4 className='meet-title '>Mr. Shiv k. Singh</h4>
   
   <p className='meet-text'>"Our esteemed Director, Mr. Shiv K. Singh, received the prestigious Lifetime Achievement Award from the Global High School Cricket Cup. Presented by renowned international cricketers Dav Whatmore and Saeed Ajmal, this honor recognizes Mr. Singh's outstanding contributions to cricket and his steadfast commitment to developing young talent."</p>
   </div>
   <div className='col-12'>
   <img className='hero-meet-img' src='./shivsirevent-3.webp' alt='ff'/>
   </div>
    </div>
    
    <Swiper
          className=" Carouseldiv "
          modules={[Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          speed={1000}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          >
    
    <div className='row'>


    
    </div>
    </Swiper>
    <Swiper
          className=" Carouseldiv "
          modules={[Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          speed={1000}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          >
    <div className='row'>
   
    {data.Section3.map((NewData)=>{
  return(
    <SwiperSlide>
       <div className='meet-images'>
    <img className='imgs-meet1' src={NewData.Image} alt='ff'/>
    </div>
      </SwiperSlide>
       )
      })}
    </div>
    </Swiper>
    
    </div>
  
    </>
  )
}

export default Brandstory1