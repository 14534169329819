import React from 'react'
import './Carousel.css'
// import AOS from 'aos';
// import { Link } from 'react-router-dom';
const Carousel = () => {
  // useEffect(() => {
  //   AOS.init();
  // }, []);
  // useEffect(() => {
  //   AOS.refresh();
  // }, []);
  // useEffect(() => {
  //   document.body.style.overflowX = 'hidden';
  //   return () => {
  //     document.body.style.overflowX = 'auto';
  //   };
  // }, []);
  // const handleAnimationEnd = () => {
  //   const logooElement = document.querySelector('.logoo');
  //   if (logooElement) {
  //     logooElement.style.display = 'none';
  //   }};
  return (

    <div className='pad'>
      <video  autoPlay loop muted>
                <source src="herovideo.mp4" type="video/mp4"  />
                Your browser does not support the video tag.
            </video>
    </div>
  )
}
export default Carousel