import React from 'react'
import './Holidayclub.css'
import Holidayclub1 from './Holidayclub1'
import Holidayclub2 from './Holidayclub2'
// import Holidayclub3 from './Holidayclub3'
import Holidayclub4 from './Holidayclub4'
const Holidayclub = () => {
  return (
    <div className='mt-5'>
        <img src='./Holidayimg1.webp' className='img1' alt=''/>
    <Holidayclub1/>
    <Holidayclub2/>
    {/* <Holidayclub3/> */}
    <Holidayclub4/>
    </div>
  )
}

export default Holidayclub