import React from 'react';
import data from "../DetailsPage/DetailsPage.json";
import "../DetailsPage/DetailsPage1.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF,  faLinkedinIn, faInstagram   } from "@fortawesome/free-brands-svg-icons";

const Ceo = () => {
  return (
    <div className="container maindetaildiv mt-md-5 mb-5">
      <div className="row">
        {data.Section1.map((DetailData) => {
          return (
            <>
              <div className="col-lg-4 col-md-5 col-sm-12">
                <img src={DetailData.Image} alt="errr" className="DetailsImg" />
              </div>
              <div className="col-lg-8 col-md-7 col-sm-12">
                <h1 className="DetailsPageHead">{DetailData.Heading}</h1>
                <h6 className="DetailsPageSubHead">{DetailData.SubHeading}</h6>
                <p className="DetailsPageContent">{DetailData.Content}</p>
                <div className="abtceo-message-social-media d-flex justify-content-between ">
                  <div className="social-circle-fb">
                <a href={DetailData.fblink} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className="socialiconfb" icon={faFacebookF} /></a></div>
                <div className="social-circle-linkedin">
                    <a href={DetailData.linkedinlink} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon className="socialiconlinkedin" icon={faLinkedinIn} /></a></div>
                    <div className="social-circle-insta">
                    <a href={DetailData.instalink} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon  className="socialiconinsta"  icon={faInstagram} /></a></div> 
                </div>
              </div>
             
              </>
          );
        })}
      </div>
    </div>  
  )
}
export default Ceo