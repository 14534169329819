import React from 'react'
import data from "./BusinessTech.json";
import  "./BusinessTech.css";
const BusinessTech = () => {
  return (
    <div className=' pt-5 pb-5 ' >
       <h3 className=" text-center BSTdiv">IT SOLUTIONS</h3>
    <div className='container mt-md-4 mb-4'>
         <div className='row bg-white'>
            {data.Tech.map((NewData,index) => {
                return(
                    <div key={index} className={`col-lg-4 col-md-6 mt-5 d-flex align-items-center justify-content-center`}>
                    
                      <div className={`card border-0 rounded-0 businesscardbox`}>
                      <a className="alink" href={NewData.Link}>
                        <img className={`card-img-top businesscardimg`} width={500} height={270} alt='pp'  src={NewData.Image}/>
                        <div className='card-body business-col-div'>
                        <h5 className={`card-title Head`}>{NewData.Heading}</h5>
                        <p className={`card-text Text`}>{NewData.Content}</p>
                        </div>
                        </a>
                      </div>
                      
                    </div>
                )
            })}
    </div>
    </div>
   
</div>
  )
}
export default BusinessTech