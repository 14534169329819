import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import data from "./OurCommitment.json";
import  "./OurCommitment.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

library.add(faLocationDot, faPhone, faEnvelope);


const OurCommitment = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [nameInput, setNameInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    

    emailjs.sendForm('service_jvoxnkp', 'template_r1aqy12', form.current, 'vpigVXDjQF8Z0u1eF')
      .then((result) => {
          console.log(result.text);
          form.current.reset();
          setNameInput('');

          setTimeout(() => {
            setIsSubmitted(false);
          }, 1000);
      }, (error) => {
          console.log(error.text);
      });
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    if (/^[A-Za-z\s]+$/.test(inputValue) || inputValue === '') {
      setNameInput(inputValue);
    }
  };
  const handleNumberChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    const limitedNumericValue = numericValue.slice(0, 10);
    setPhoneInput(limitedNumericValue); 
  };

  return (
    <div>
  <div className="sustain-bg-img"  >
    <div className="col-12 sustain-mini container  " >
      <div className="col-lg-5 col-md-10 sustain-inner row  " >
        
       {
        data.Section1.map((NewData, index) =>{
          return(
            <div key={index}>
              <h1 className="Heding-sustain" >{NewData.Heading}</h1>
              </div>
          )
        })
       }
       {
        data.Section2.map((NewData) =>{
          return(
            <div>
              <h4 className="SubHeding-sustain" >{NewData.Heading}</h4>
              </div>
          )
        })
       }
       {
        data.Section3.map((Newdata) =>{
          return(
            <div>
              <div className=" icons-section col-10 " >   
              <div className="col-1" >
              <FontAwesomeIcon
                      icon={[Newdata.Icon.library, Newdata.Icon.iconName]}
                      className="ICONS"
                    />
                </div>
                <p className=" ms-3 icons-text" >{Newdata.Text}</p>
              </div>
            </div>
          )
        })
       }
      
       <div  className="last-icons col-lg-6 d-flex justify-content-center align-items-center">
       {
        data.Section5.map((NewDta) =>{
          return(
            <div className='ms-5'>
              <a href='https://www.facebook.com/womekiinvestorsclub' target="_blank" rel='noopener noreferrer' >
              <img className="ICONS" src={NewDta.Icon} alt="ll" /></a>
            </div>
          )
        })
       }
       {
        data.Section6.map((NewData) =>{
          return(
            <div className='ms-5'>
               <a href='https://www.linkedin.com/company/womeki-investors-club-wic/' target="_blank" rel='noopener noreferrer' >
                <img className="ICONS " src={NewData.Icon} alt="jj" /></a>
              </div>
          )
        })
       }
       {
        data.Section7.map((NewDta) =>{
          return(
            <div className='ms-5'>
               <a href='https://www.instagram.com/womekiinvestorsclub/?igshid=OGQ5ZDc2ODk2ZA%3D%3D' target="_blank" rel='noopener noreferrer'>
               <img className="ICONS " src={NewDta.Icon} alt="nk" /></a> </div>
          )
        })
       }
     
       </div>
      </div>

      <div className="col-lg-5 col-md-12 sustain01 " >
        <div className="container" >
          <div className="row" >
          <h1 className="heading-form" >Get In Touch</h1>
            <div className="col-12 inputs-div " >
              <form ref={form} onSubmit={sendEmail}>
            <input className="input " type='text' name='to_name' placeholder="Enter your Name" value={nameInput}  onChange={handleNameChange}  required/>
            <input className="input " type="email" name='from_name' placeholder="Enter your Email"  required/>
            <input className="input " type='tel' name='message' placeholder="Enter your Contact"  value={phoneInput}   onChange={handleNumberChange}/>
            <button className="form-button" type="submit" >SUBMIT</button>
            </form>
            {isSubmitted && (
            <div className="notification">
              Form submitted successfully!
            </div>
          )}
            
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
      </div>
  );
};
export default OurCommitment;