import React from 'react'
import './Blogpage.css'
import data from './BlogpageOne.json'

const BlogpageOne = () => {
  return (
    <>
    <div className='blogone-hero-div'>
      <img className='blogs-bg-img' src='/BlogImg/blogimg5.webp' alt='ss'/>
      <div className='overlay'>
      <h1 className='blogone-hero-title'>Real estate investment strategies for beginners</h1>
      </div>
    </div> 
    <div className='container-fluid p-md-5'>
      <div className='row'>
        <div className='col-12'>
          {data.Section1.map((NewData, index)=>{
            return(
              <p key={index} className='blog-content '>{NewData.Content}</p>
            )
          })}
            </div> 
            
          {
            data.Section2.map((NewData, index) =>{
              return(
                <>                
                <div key={index} className='col-md-6 col-sm-12'>
              <p className='blog-content'>{NewData.Content}</p>
            </div>
          <div className='col-md-6 col-sm-12'>
<img className='blog-images' src={NewData.Image} alt='dd'/>
          </div>
          </>

              )
            })
          }
        </div>
        <div className='row mt-4'>
          {data.Section3.map((NewData, index) =>{
           return(
            <>
            <p className='blog-content'>{NewData.Content}</p>
            <img className='blog-last-images ' src={NewData.Image} alt='dd'/>
            </>
           )
          })}
        </div>
        </div>
    
    </>
  )
}

export default BlogpageOne
