import React from 'react'
import './pages/News/News.css'
import "../Components/pages/News/News.css";
import Data from './Newspage.json'
const TempNews = () => {
  return (
    <>
    <div className="NewsMainDivs">
    
      <div className=" newswomekiheads">
        <h1 className=" text-center newswomekiheadings mt-5 " >Womeki Times</h1>
      </div>
      
    <h1 className="text-center newsmaintitles" >News</h1>
    <div >
    <div className="fluid">
      <div className="container mt-5 maindivs">
        <div className="row">
          <div className="col-12 text-center">
          <div>
          {
              Data.Section1.map((NewData) =>{
                return(
                    <div>
                  <img   className="newsimgs mt-5 " src={NewData.Image} alt="vgg"/>
                  {/* <div className="overDivs">
                  <h4 className="newsheadss">{NewData.Heading}</h4>
                  <p className="newscontents">{NewData.Text}</p>
                  <p className="mt-5 newsparas">{NewData.Time}</p>
                  </div> */}
                  </div>
                )
              })
             }
          </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  
      <div className="text-center newswomekihead1s">
        <span>
          <h1 className="text-center newswomekiheadings" >
            Womeki
          </h1>
        </span>
      </div>
    </div>
  
    </>
  )
}
export default TempNews