import React from 'react';
import data from "./DetailsPage.json";
import  "./DetailsPage1.css";
const DetailsPage3 = () => {
  return (
    <div className="container maindetaildiv">
    <div className="row mb-5">
      {data.Section3.map((DetailData) => {
        return (
          <div className="d-flex FlexDiv">
            <div className="col-lg-4  col-md-5 col-sm-12">
              <img src={DetailData.Image} alt="errr" className="DetailsImg" />
            </div>
            <div className="col-lg-8   col-md-7 col-sm-12  SidePadding">
              <h3 className="DetailsPageHead">{DetailData.Heading}</h3>
              <h6 className="DetailsPageSubHead">{DetailData.SubHeading}</h6>
              <p className="DetailsPageContent">{DetailData.Content}</p>
            </div>
          </div>
        );
      })}
    </div>
  </div>
  )
}
export default DetailsPage3