import React from "react";
import data from "./DetailsPage.json";
import "./DetailsPage1.css";

const DetailsPage1 = () => {
  return (
    <div className="container maindetaildiv">
      <div className="row">
        {data.Section1.map((DetailData) => {
          return (
            <>
              <div className="col-md-4 col-sm-12">
                <img src={DetailData.Image} alt="errr" className="DetailsImg" />
              </div>
              <div className="col-md-8 col-sm-12">
                <h1 className="DetailsPageHead">{DetailData.Heading}</h1>
                <h6 className="DetailsPageSubHead">{DetailData.SubHeading}</h6>
                <p className="DetailsPageContent">{DetailData.Content}</p>
              </div>
              </>
          );
        })}
      </div>
    </div>
  );
};
export default DetailsPage1;