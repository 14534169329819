import React from 'react';
import "./Infra.css";
const Infra = () => {
    return (
        <div className='body' >
            <div class="uc__wrapper">
                <div class="uc__details">
                    <h1 class="title">Coming Soon!</h1>
                    <h3 class="intro">
                        We are working hard to give you a better experience.
                    </h3>
                    <p class="uc__description">
                        We are working hard on it & the site may go live very soon. We promise, it will be worth the wait!
                    </p>
                    <div class="uc__subscribe">
                        <h3>Get Notified When We Go Live</h3>
                        <div class="uc__form">
                            <form action="#">
                                <input type="email" class="email" placeholder="Email Address.." />
                                <input type="submit" class="submit" value="Get Notified" />
                            </form>
                        </div>
                    </div>
                </div>
                <div class="uc__art">
                    <img className='img-side-commingsoon' src="./VIEW5.jpg" alt="jj" />
                </div>
            </div>
        </div>
    )
}
export default Infra