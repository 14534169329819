import React from 'react'
import data from "./EducationCard2.json"
import  "./Educationcards.css"
const EducationCard2 = () => {
  return (
  
    <div className='edupagediv'>
    {data.Section1.map((NewData,index) => {
      return(
        <div key={index}>
          <img className='Section1Img mt-2' src={NewData.Image} alt="img" />
          <div className='container mt-4'>
            <h2 className="educationhead">{NewData.Heading}</h2>
            <p className="educationcontent">{NewData.Content}</p>
          </div>
        </div> 
      )
    })}
      <div className='container mt-5'>
     {data.Section2.map((NewData, index) => {
      return(
      <div key={index} className={`row mt-5 educationcardrow`}>
        <div className=' col-lg-5 col-md-4 col-sm-12'>
          <img className='Section2Img' alt='aa' src={NewData.Image}/>
        </div>
        <div className=' col-lg-7 col-md-8 col-sm-12  d-flex flex-column justify-content-center align-items-center'>
          <p className="educationcontent">{NewData.Content}</p>
        </div>
      </div>
       )
      })}
{data.Section3.map((NewData,index) => {
      return(
      <div key={index} className='row mt-5 d-flex flex-column-reverse flex-md-row'>
        <div className=' col-lg-7 col-md-8 col-sm-12  d-flex flex-column justify-content-center align-items-center'>
          <p className="educationcontent">{NewData.Content}</p>
        </div>
        <div className=' col-lg-5 col-md-4 col-sm-12'>
          <img className='Section3Img' alt='aa' src={NewData.Image}/>
        </div>
      </div>
       )
      })}
{data.Section4.map((NewData,index) => {
      return(
      <div key={index} className={" mt-md-5"}>
        <p className="educationcontent">{NewData.Content}</p>
        </div>
       )
      })}
      </div>
    </div>
  )
}
export default EducationCard2