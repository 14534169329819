import React from "react";
import  "./Footer.css";
const Footer = () => {
  return (
    <>
     <div className="footer-div " >
      <div className="container footerdivs" >
        <div className="row" >
          <div className=" col-12" >
          <img className="footer-logo" src="./grouplogo.png" alt="ll" />
          <div className=" d-flex justify-content-evenly " >
          <li className="list-footer  "  >
                <a className="list-footers" href="/" >HOME</a>
              </li>
              <li className="list-footer"  >
                <a className="list-footers" href="/about" >ABOUT</a>
              </li>
              
             <li className="list-footer"  >
                <a className="list-footers" href="/BusinessMeet" > WOMEKI INTERNATIONAL</a>
              </li>
             <li className="list-footer"  >
                <a className="list-footers" href="/contact" >CONTACT</a>
              </li>
              </div>
              <p className=" copywrite-text " > All Right Reserved By Womeki Tech Solutions.</p>
          </div>
        </div>
      </div>
    
     </div>
    </>
  );
};
export default Footer;