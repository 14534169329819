import React from "react";
import data from "./NewsCard.json";
import "./NewsCard.css";

const LatestNews = () => {
  return (
    <div>
      <div className="fluid mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 News_Card">
              {data.Section1.map((NewsCardData, index) => {
                return (
                  <div key={index} className="mt-5 NewsSection">
                    <p className="newspara1">
                      <img
                        src={NewsCardData.Image}
                        alt="News-img"
                        width={20}
                        height={20}
                      />
                      <a  className="TextLink" href={NewsCardData.PostURL}>
                      {NewsCardData.Content}
                      </a>
                    </p>
                    <p className="newspara2">
                      <a className="TextLink" href={NewsCardData.URL}>
                        {NewsCardData.Content1}
                        <span className="ms-3">
                          <img
                            src={NewsCardData.Image1}
                            alt="News-img"
                            width={12}
                            height={12}
                          />
                        </span>
                      </a>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LatestNews;