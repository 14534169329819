import React from 'react';
import "./Events.css"

const Events = () => {
  return (
    <div className='container' >
        <div className='row d-flex justify-content-around ' >
      <div className=' col-1' ></div>
      <div className='events-main col-lg-9' >
        <div className='heading-event' >
            <h2 className='clevent-heading' >Red F.M  Event Hosted By Womeki </h2>
        </div>
        <div className='img-events' >
       <img className='events-image' src='./event-9.jpg' alt='oo' />
        </div>
      </div>
      <div className=' col-1' ></div>
      </div>
    </div>
  )
}

export default Events
